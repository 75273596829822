import React, { useState } from "react";
import styled from "styled-components";
import "../components/Vote.css";
import VoteButton from "./VoteButton";
import buttonIcons from "../constants/buttonIcons";
import colors from "../constants/colors";
import { Formik } from "formik";
import VoteIcon from "./VoteIcon";
import { Paper } from "@material-ui/core";

import { Button, Input } from "../utils/styled-components-library";

const Header = styled.th`
  color: #212121;
`;

const Cell = styled.td`
  color: #212121;
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0px;
  tbody tr:nth-child(even) {
    background: #eeeeee;
  }
`;
const VoteInfoContainer = styled.div`
  background-color: ${(props) => props.colors[1]};
  padding: 10px;
  border-radius: 3px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  width: 400px;
  max-width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 22px;
  margin-bottom: 25px;
  margin-top: 25px;

  @media (max-width: 500px) {
    width: 300px;
    font-size: 16px;
    height: 40px;
  }
`;

const RoundedVoteVotedMessage = styled.div`
  padding-top: 10px;
`;

const VoteInfo = ({ selectedOption }) => (
  <Paper
    className="VoteInfoContainer"
    style={{ backgroundColor: colors[selectedOption.optionType][0] }}
  >
    Vous avez voté {selectedOption.optionName}
    <VoteIcon
      iconName={buttonIcons[selectedOption.optionType]}
      style={{ marginLeft: 10 }}
    />
  </Paper>
);

const getVoteOptionName = (vote, selectedOption) => {
  let voteOption = vote.options.find(
    (option) => option.optionId === selectedOption
  );

  return voteOption.optionType === "voteAbstain" &&
    vote.voteType !== "resolutionVote"
    ? "Vote(s) blanc(s)"
    : voteOption.optionName;
};

export default function VoteTile({
  vote,
  canVote,
  isVoting,
  selectedOptions,
  onVote,
  disabled,
  isDelegate,
}) {
  const [selectedOffListCandidateId, setSelectedOffListCandidateId] =
    useState("");

  const optionsGroupByType = () => {
    let candidates = [],
      offListCandidates = [],
      basicOptions = [];

    for (const option of vote.options) {
      const metadata = JSON.parse(option.optionMetadata);
      if (!metadata) {
        basicOptions.push(option);
      } else {
        candidates.push(option);
        if (metadata.isOffList) offListCandidates.push(option);
      }
    }

    return {
      candidates: candidates.sort((a, b) => {
        const isAOfflist = JSON.parse(a.optionMetadata).isOffList;
        const isBOfflist = JSON.parse(b.optionMetadata).isOffList;
        if (isAOfflist == isBOfflist) {
          return a.optionId > b.optionId ? 1 : -1;
        }
        return isAOfflist ? 1 : -1;
      }),
      offListCandidates: offListCandidates,
      basicOptions: basicOptions,
    };
  };

  const voteOptions = optionsGroupByType();

  const handleOfflistCandidatesSelectChange = (e) => {
    const value = e.target.value ? parseInt(e.target.value) : "";
    setSelectedOffListCandidateId(value);

    if (!value && selectedOptions) {
      const index = selectedOptions.indexOf(value);
      selectedOptions.splice(index, 1);
    }
  };

  selectedOptions = selectedOptions || [];

  return (
    <div
      style={{
        display: "flex",
        flex: vote.voteType !== "resolutionVote" ? 5 : 3,
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
        {vote.voteType === "oneRoundElection" ||
        vote.voteType === "twoRoundsElection" ||
        vote.voteType === "plurinominalElection" ||
        vote.voteType === "parityPlurinominalElection" ? (
          <Table>
            <thead>
              <tr>
                {/* <Header>N° option</Header> */}
                {vote.voteType === "parityPlurinominalElection" && <Header>{vote.genderLabel}</Header>}
                <Header>{vote.lastNameLabel}</Header>
                <Header>{vote.firstNameLabel}</Header>
                <Header>{vote.infoLabel}</Header>
              </tr>
            </thead>
            <tbody>
              {/* All candidates */}
              {voteOptions.candidates.map((option, index) => {
                const metadata = JSON.parse(option.optionMetadata);
                if (
                  ((vote.voteType !== "plurinominalElection" && vote.voteType !== "parityPlurinominalElection") &&
                    !metadata.isOffList) ||
                  ((vote.voteType === "plurinominalElection" || vote.voteType === "parityPlurinominalElection") &&
                    (!metadata.isOffList ||
                      (selectedOptions &&
                        selectedOptions.includes(option.optionId))))
                ) {
                  return (
                    <tr key={`candidate${index}`}>
                      {/* <Cell>
												{metadata.isOffList ? "Hors liste" : index + 1}
											</Cell> */}
                      {vote.voteType === "parityPlurinominalElection" && <Cell>{metadata.gender === "man" ? "Monsieur" : "Madame"}</Cell>}
                      <Cell>{metadata.lastname}</Cell>
                      <Cell>{metadata.firstname}</Cell>
                      <Cell>{metadata.info}</Cell>
                      <td>
                        <Button
                          onClick={() => onVote(option.optionId)}
                          style={{ background: "#00bc58" }}
                          disabled={
                            disabled || 
                            (vote.voteType !== "plurinominalElection" && 
                             vote.voteType !== "parityPlurinominalElection" &&
															selectedOptions?.includes(option.optionId)) ||
														(vote.excludedVoterId && !isDelegate) ||
														vote.isFrozen
                          }
                        >
                          <span style={{ fontWeight: 500 }}>
                            {!selectedOptions?.includes(option.optionId)
                              ? "Voter"
                              : "Vote confirmé !"}
                          </span>
                        </Button>
                      </td>
                    </tr>
                  );
                }
                return null;
              })}

              {/* Select input for candidates that are off list */}
              {voteOptions.offListCandidates?.length ? (
                <tr>
                  {/* <Cell>(Hors liste)</Cell> */}
                  <td colSpan={vote.voteType === "parityPlurinominalElection" ? "4" : "3"} style={{ paddingRight: "5px" }}>
                    <Formik>
                      <Input
                        onChange={handleOfflistCandidatesSelectChange}
                        component="select"
                        name="offListCandidates"
                        style={{ marginBottom: "0", padding: "5px" }}
                      >
                        <option value="">
                          Sélectionnez un(e) candidat(e) hors liste
                        </option>
                        {voteOptions.offListCandidates.map((option, index) => {
                          if (
                            (vote.voteType !== "plurinominalElection" && vote.voteType !== "parityPlurinominalElection") ||
                            !selectedOptions.length ||
                            !selectedOptions.includes(option.optionId)
                          ) {
                            let candidate = JSON.parse(option.optionMetadata);
                            let displayedName = `${candidate.firstname} ${
                              candidate.lastname
                            }${candidate.info ? ` (${candidate.info})` : ""}`;
                            if (vote.voteType === "parityPlurinominalElection") {
                              displayedName = ` ${displayedName} - ${candidate.gender === "man" ? "Monsieur" : "Madame"}`;
                            }
                            return (
                              <option
                                key={`offListCandidate${index}`}
                                value={option.optionId}
                              >
                                {displayedName}
                              </option>
                            );
                          }
                          return null;
                        })}
                      </Input>
                    </Formik>
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        console.log('selectedOffListCandidateId', selectedOffListCandidateId);
                        onVote(selectedOffListCandidateId)
                        setSelectedOffListCandidateId('');
                      }}
                      style={{ background: "#00bc58" }}
                      disabled={
                        disabled ||
                        (vote.voteType !== "plurinominalElection" && 
                          vote.voteType !== "parityPlurinominalElection") ||
                        selectedOffListCandidateId === "" ||
                        (vote.excludedVoterId && !isDelegate) ||
                        vote.isFrozen ||
                        selectedOptions?.includes(selectedOffListCandidateId)
                      }
                    >
                      <span style={{ fontWeight: 500 }}>
                        {!selectedOptions?.includes(selectedOffListCandidateId)
                          ? "Voter"
                          : "Vote confirmé !"}
                      </span>
                    </Button>
                  </td>
                </tr>
              ) : null}

              {/* Other vote options */}
              {voteOptions.basicOptions.map((option, index) => {
                return (
                  <tr key={`option${index}`}>
                    {/* <Cell colSpan="4"> */}
                    <Cell colSpan={vote.voteType === "parityPlurinominalElection" ? "4" : "3"}>
                      {vote.voteType === "oneRoundElection" ||
                      vote.voteType === "twoRoundsElection" ||
                      vote.voteType === "plurinominalElection" ||
                      vote.voteType === "parityPlurinominalElection"
                        ? "Vote Blanc"
                        : option.optionName}
                    </Cell>
                    <td>
                      <Button
                        onClick={() => onVote(option.optionId)}
                        style={{ background: "#FFA726" }}
                        disabled={
                          disabled ||
                          (vote.voteType !== "plurinominalElection" &&
                            vote.voteType !== "parityPlurinominalElection" &&
                            selectedOptions?.includes(option.optionId)) ||
                          (vote.excludedVoterId && !isDelegate) ||
                          vote.isFrozen
                        }
                      >
                        {/* TODO: FIX HERE FOR ONLY VOTE BLANCS */}
                        <span style={{ fontWeight: 500 }}>
                          {!selectedOptions?.includes(option.optionId)
                            ? vote.voteType === "oneRoundElection" ||
                              vote.voteType === "twoRoundsElection" ||
                              vote.voteType === "plurinominalElection" ||
                              vote.voteType === "parityPlurinominalElection"
                              ? "Voter Blanc"
                              : option.optionName
                            : "Vote confirmé !"}
                        </span>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <>
            {vote.options.map((option) => (
              <VoteButton
                key={option.optionId}
                label={option.optionName}
                colors={colors[option.optionType]}
                icon={buttonIcons[option.optionType]}
                enabled={
                  !disabled &&
                  canVote &&
                  !isVoting &&
                  (!vote.excludedVoterId || isDelegate) &&
                  !vote.isFrozen
                }
                onClick={() => onVote(option.optionId)}
                selected={selectedOptions?.includes(option.optionId)}
              />
            ))}
          </>
        )}
      </div>

      {selectedOptions?.length > 0 &&
        (vote.voteType === "oneRoundElection" ||
          vote.voteType === "twoRoundsElection") && (
          <RoundedVoteVotedMessage>
            Vous avez donné votre voix à{" "}
            {getVoteOptionName(vote, selectedOptions[0])}.{" "}
            {!vote.isFrozen
              ? `Pour changer votre vote, cliquez sur le bouton "Voter" d'un autre
						candidat.`
              : ""}
          </RoundedVoteVotedMessage>
        )}

      {vote.voteType === "resolutionVote" && selectedOptions?.length > 0 && !vote.isFrozen && !disabled && (
        <>
          <VoteInfo
            selectedOption={vote.options.find(
              (option) => option.optionId === selectedOptions[0]
            )}
          />
          {!vote.isFrozen && (
            <span style={{ color: "#2c3e50", fontWeight: "500" }}>
              Vous souhaitez modifier votre vote ? Cliquez sur un autre bouton !
            </span>
          )}
        </>
      )}
    </div>
  );
}
